import React from "react"
import { SearchProvider } from "./src/context/SearchContext"

/**
 * Add Search Context to application
 *
 * @param element
 * @returns {JSX.Element}
 */
export const wrapRootElement = ({ element }) => (
  <SearchProvider>{element}</SearchProvider>
)