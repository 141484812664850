import React from "react"

const DEFAULT_STATE = {
  isDefault: true,
  refinementList: {
    original: [],
    format: [],
    genre: [],
    target: []
  }
}
const SearchContext = React.createContext({
  setRefinementList: () => {
  },
  ...DEFAULT_STATE
})

class SearchProvider extends React.Component {
  state = DEFAULT_STATE
  setRefinementList = refinementList => {
    this.setState({ refinementList })
    this.setIsDefault()
  }
  setIsDefault = () => {
    const { original, format, genre, target } = this.state.refinementList
    if ([...original, ...format, ...genre, ...target].length === 0) {
      this.setState({ isDefault: true })
    }
    this.setState({ isDefault: false })
  }
  reset = () => {
    this.setState(DEFAULT_STATE)
  }

  render() {
    const { children } = this.props
    const { refinementList, isDefault } = this.state
    return (
      <SearchContext.Provider
        value={{
          isDefault,
          refinementList,
          setRefinementList: this.setRefinementList,
          reset: this.reset
        }}
      >
        {children}
      </SearchContext.Provider>
    )
  }
}

export default SearchContext

export { SearchProvider }